<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Delivery Challan No.</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Send To</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(delivery_challan, i) in delivery_challans.data" :key="i">
              <td>{{ delivery_challans.from + i}}</td>
              <td>{{ delivery_challan.delivery_challan_number }}</td>
              <td>
                <span v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_REJECTED')"><a-tag color="#f50">Receiver Rejected</a-tag></span>
                <span v-else>
                  <a-tag color="#f50" v-if="(delivery_challan.delivery_challan_status.includes('SENDER_SEND') || delivery_challan.delivery_challan_status.includes('RECEIVER_PENDING')) && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')">Sender Send</a-tag>
                  <a-tag color="#87d068" v-else-if="delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED') && !delivery_challan.delivery_challan_status.includes('RECEIVER_REJECTED')">Receiver Received</a-tag>
                </span>
                <a href="javascript: void(0);" v-b-modal.delivery-challan-status-modal @click="$bvModal.show('delivery-challan-status-modal'), status(delivery_challan.max_delivery_challan_status, delivery_challan)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>{{ customDate(delivery_challan.dispatch_date) }}</td>
              <td>{{ delivery_challan.delivery_to ? delivery_challan.delivery_to.name : 'N/A' }}</td>
              <td>
                <span>
                  <router-link :to="{ name: 'hubDeliveryChallanView', params: { delivery_challan_id: delivery_challan.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
                </span>
                <span v-if="delivery_challan.delivery_challan_status.includes('RECEIVER_PENDING') && !delivery_challan.delivery_challan_status.includes('RECEIVER_RECEIVED')">
                  <router-link :to="{ name: 'senderDeliveryChallanUpdate', params: { delivery_challan_id: delivery_challan.id } }" class="btn btn-sm btn-success mr-2" tag="a">
                  <i class="fe fe-edit"/> Update
                  </router-link>
                   <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(delivery_challan.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
                </span>
                <a-dropdown placement="bottomRight" :trigger="['click']">
                  <button type="button" class="btn btn-outline-success" style="padding: 0 .75rem !important;">
                    <i class="fe fe-more-vertical" /> Download
                  </button>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <a-button class="btn btn-outline-success btn-block mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(delivery_challan.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> PDF</a-button>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="delivery_challans.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="delivery_challans" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :delivery_challan="delivery_challan"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/deliveryChallan/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      delivery_challans: {},
      delivery_challan: {},
      loading: false,
      loader: false,
      flag: false,
      btnLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      index: -1,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/sender/delivery_challan/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.delivery_challans = response.data.delivery_challans
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/sender/delivery_challan/search?page=' + page, this.search_data)
        .then(response => {
          this.delivery_challans = response.data.delivery_challans
        })
    },
    onDelete(deliveryChallanId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/sender/delivery_challan/' + deliveryChallanId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    pdfDownload(deliveryChallanId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/delivery-challan/pdf-download/' + deliveryChallanId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'delivery_challan.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    excelDownload(deliveryChallanId, index) {
      this.excelDownloadLoader = true
      apiClient.get('api/delivery-challan/excel-download/' + deliveryChallanId, { responseType: 'blob' }).then(response => {
        this.excelDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'delivery_challan.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    status(deliveryChallanStatus, deliveryChallan) {
      this.current = 0
      this.delivery_challan = ''
      this.current = deliveryChallanStatus
      this.delivery_challan = deliveryChallan
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
